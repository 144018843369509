import Head from 'next/head'
import Layout, { siteTitle } from '../components/layout'
import utilStyles from '../styles/utils.module.css'
import Link from "next/link";

export default function Home() {
  return (
      <Layout home>
        <Head>
          <title>{siteTitle} - виртуальный тур по квартире/дому в Томске</title>
          <meta name="description" content="Фото и видео съёмка 360 в Томске. Виртуальные туры. Видео на 360 градусов." />
          <meta name="keywords" content="Виртуальный тур по дому, 3D тур по квартире, панорамное видео, сферическое видео, панорамная видеосъемка, виртуальная реальность, VR" />
        </Head>
        <section className={utilStyles.headingMd}>
          <p>Приветствуем вас на сайте фотографий 360 и 3D туров. Мы ещё работаем над сайтом.</p>
            <p>Но уже сейчас Вы можете ознакомиться с нашими <Link href="/posts/service">3D турами</Link>, фотографиями, вступить в наши <Link href="/posts/social"> группы в соцсетях</Link> или поделиться
              своими фотографиями/виртуальным туром.
          </p>

          <p>
              <Link href="/posts/service">Предоставляем услуги фото- и видеосъемки 360(г.Томск).</Link>
          </p>
          <p>
              <Link href="https://samnaydu.ru">Снять квартиру в Томске без посредника</Link>
          </p>
        </section>
          <footer>
              <a href="https://в360.рф">в360.рф</a>
          </footer>
      </Layout>
  )
}